<template>
    <v-sheet class="bg-transparent pa-12" rounded>
      <v-card class="mx-auto px-6 py-8" max-width="344">
        <div class="text-center text-h5 text-uppercase">Yeni müşteri bilgileri</div>
        <br>
        <v-form v-model="form" @submit.prevent="onSubmit">
            <v-file-input clearable label="Logo" accept="image/*" v-model="image"></v-file-input>
            <v-text-field variant="outlined" v-model="title" :readonly="loading" :rules="[required]" class="mb-2" clearable
            label="Firma Adı"></v-text-field>
  
          <v-text-field variant="outlined" type="phone" v-model="phone" :readonly="loading" :rules="[required]" class="mb-2" clearable
            label="Telefon" ></v-text-field>

            <v-text-field variant="outlined" type="email" v-model="email" :readonly="loading" :rules="[required]" class="mb-2" clearable
            label="E-posta"></v-text-field>

            <v-text-field variant="outlined" v-model="website" :readonly="loading" :rules="[required]" class="mb-2" clearable
            label="Website"></v-text-field>

            <v-textarea label="Adres" variant="outlined" v-model="address" :readonly="loading" :rules="[required]" class="mb-2" clearable
            ></v-textarea>

          <br>
  
          <v-btn :disabled="!form" :loading="loading" block color="deep-orange-accent-4" size="large" type="submit"
            variant="elevated">
            Gönder
          </v-btn>
        </v-form>
      </v-card>
    </v-sheet>
  </template>
  <script>
import axios from 'axios'

  export default {
    data: () => ({
      hide_password: true,
      form: false,
      image: null,
      title: null,
      phone: null,
      email: null,
      website: null,
      loading: false,
    }),
  
    methods: {
      async onSubmit() {
        if (!this.form) return
  
        this.loading = true
        let form = new FormData()
        form.append("image", this.image)
        form.append("title", this.title)
        form.append("phone", this.phone)
        form.append("email", this.email)
        form.append("website", this.website)
        axios
          .post('https://app.xsada.net/api', form)
          .then((response) => console.log(response))

        //
      },
      required(v) {
        return !!v || 'Field is required'
      },
    },
  }
  </script>